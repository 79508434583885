<template>
<div>
    <div class="box p-5 zoom-in scroll-ajuste table_cards">
        <p class="font-medium" style="font-size: 16px">Cumplimiento Referencias</p>
        <div class="grid grid-cols-2 gap-4" >
            <div class=" gap-4">
                <div class="flex flex-col">
                    <span class="font-medium">
                        Total Ref Solicitadas
                    </span>
                    <span class="text-gray-600">
                    {{$h.formatNumber(dataCumplimiento.referenciasSolicitadas)}}
                    </span>
                </div>
                <div class="flex flex-col">
                    <span class="font-medium">
                        Ref con Entrega Total
                    </span>
                    <span class="text-gray-600">
                        {{$h.formatNumber(dataCumplimiento.referenciasEntregadas)}}
                    </span>
                </div>
                <div class="flex flex-col">
                    <span class="font-medium">
                        Ref con Entrega Parcial
                    </span>
                    <span class="text-gray-600">
                        {{$h.formatNumber(dataCumplimiento.referenciasEntregadasParcial)}}
                    </span>
                </div>
                <div class="flex flex-col">
                    <span class="font-medium">
                        Ref Agotadas
                    </span>
                    <span class="text-gray-600">
                        {{$h.formatNumber(dataCumplimiento.referenciasAgotadas)}}
                    </span>
                </div>
                <div class="flex flex-col">
                    <span class="font-medium">
                        Ref Pendientes
                    </span>
                    <span class="text-gray-600">
                        {{$h.formatNumber(dataCumplimiento.referenciasPendientes)}}
                    </span>
                </div>
            </div>
            <div class=" gap-4">
                <!-- chart -->
                <div class="flex flex-col">
                    <span class="font-medium">
                        % Referencias Entregadas
                    </span>
                    <div class="flex-none mx-auto relative">
                        <ReportDonutChart1
                        :width="120"
                        :height="120"
                        :laData="[
                            dataCumplimiento.porcentReferencias,
                            100 - dataCumplimiento.porcentReferencias
                        ]"
                        :losLabels="[
                            'Entregados', 'Pendientes'
                        ]"
                        />
                        <div
                        class="font-medium absolute w-full h-full flex items-center justify-center top-0 left-0"
                        style="z-index:-1;"
                        >
                            {{dataCumplimiento.porcentReferencias}}%
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import storeCardCumplimiento from './store/cardCumplimiento.store'
// import storeFiltro from '../store/filtro.store'
import ReportDonutChart1 from '@/components/report-donut-chart-1/Main.vue'
// import dayjs from 'dayjs'
import { computed, ref, watch } from 'vue'
export default {
  name: 'cardCumplimiento',
  components: { ReportDonutChart1 },
  setup () {
    /** data **/
    const verMas = ref(false)
    /** computed **/
    const dataCumplimiento = computed(
      () => storeCardCumplimiento.getters.dataCumplimiento
    )

    const calcularPorcent = (a, b) => {
        return parseInt((b * 100) / a, 10)
    }

    /** watch **/
    watch(verMas, (a, b) => {
      if (b) {
        getData()
      }
    })

    /** methods **/
    // const handleShow = () => {
    //   verMas.value = !verMas.value
    // }
    // const getDetalle = () => {
    //   storeCardCumplimiento.dispatch('getDetalleCumplimiento', { anio: anio.value })
    // }
    const getData = () => {
      storeCardCumplimiento.dispatch('getDataCumplimiento', { })
    }
    return {
      calcularPorcent,
      dataCumplimiento,
      // anio,
      // mes,
      // handleShow,
      verMas
      // nameMes,
      // detalleCumplimiento
    }
  }
}
</script>
<style scoped>
.table-cards{
  text-align: start;
  font-size: 0.8em;
  margin-top: 10px;
}
</style>
